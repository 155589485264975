import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Tooltip, TextField, Typography, Card, CardContent, ButtonGroup, InputAdornment } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faTrash, faFilter } from '@fortawesome/free-solid-svg-icons';

import Tables from '../../../Components/Table';
import CustomTabs from '../../../Components/Tabs';
import Alertavel from '../../../Components/Alertavel';
import ConfirmDialog from '../../../Components/Dialog/Confirm';
import ResponsiveDialog from '../../../Components/Dialog/Dialog';
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { User } from '../../../Models/User';
import { Machinery } from '../../../Models/Machinery/Machinery';
import { Property } from '../../../Models/Organization/Property';
import { Activity } from '../../../Models/Activities/Activities';
import { Contractor } from '../../../Models/Organization/Contractor';

import { getCurrentDate } from '../../../Services/globalFunction';

const ListActivities = ({ module }) => {
  const [deleteWorkedId, setDeleteWorkedId] = useState(null);
  const [deleteNotWorkedId, setDeleteNotWorkedId] = useState(null);

  const [titleWorked, setTitleWorked] = useState();
  const [titleNotWorked, setTitleNotWorked] = useState();

  /** Estado para armazenar os dados da atividade*/
  const [workedActivity, setWorkedActivity] = useState([]);
  const [notWorkedActivity, setNotWorkedActivity] = useState([]);

  const [dataWorkedActivity, setDataWorkedActivity] = useState([]);
  const [dataNotWorkedActivity, setDataNotWorkedActivity] = useState([]);

  /** Estados para exibir alertas*/
  const [openAlertWorked, setOpenAlertWorked] = useState(false);
  const [openAlertNotWorked, setOpenAlertNotWorked] = useState(false);

  const [alertWorked, setAlertWorked] = useState('');
  const [alertNotWorked, setAlertNotWorked] = useState('');

  /** Estados para exibir Dialogs */
  const [openWorkedDialog, setOpenWorkedDialog] = useState(false);
  const [openNotWorkedDialog, setOpenNotWorkedDialog] = useState(false);

  const [dialogWorked, setDialogWorked] = useState('');
  const [dialogNotWorked, setDialogNotWorked] = useState('');

  /** Estados para exibir Modais */
  const [fullscreen, setFullscreen] = useState();

  const [openWorkedModal, setOpenWorkedModal] = useState(false);
  const [openNotWorkedModal, setOpenNotWorkedModal] = useState(false);

  const [modalWorked, setModalWorked] = useState('');
  const [modalNotWorked, setModalNotWorked] = useState('');

  /** Estados para armazenar dados de talhões, máquinas e propriedades*/
  const [dataField, setDataField] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataMachinery, setDataMachinery] = useState([]);
  const [dataContractor, setDataContractor] = useState([]);

  /** Estado para armazenar os dados do formulário de filtro trabalhado. */
  const [workedForm, setWorkedForm] = useState({
    data_inicial: getCurrentDate(0, 30, 'subtrai'),
    data_final: getCurrentDate(0, 0, ''),
    contratante: [],
    propriedade: [],
    maquina: [],
    talhao: [],
  });

  /** Estado para armazenar os dados do formulário de filtro não trabalhado. */
  const [notWorkedForm, setNotWorkedForm] = useState({
    data_inicial: getCurrentDate(0, 30, 'subtrai'),
    data_final: getCurrentDate(0, 0, ''),
  });

  /** Obter o usuário e o token */
  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  /** Função para carregar as atividades com base nos filtros selecionados. @async*/
  const LoadActivity = async (tipo) => {
    let parameters;

    // Parâmetros de consulta
    if (tipo === 'worked') {
      const { data_inicial, data_final, propriedade, maquina, talhao } = workedForm;

      const listProperties = propriedade.map((val) => val.id);
      const listMachinery = maquina.map((val) => val.id);
      const listField = talhao.map((val) => val.id);

      parameters = {
        data_inicial,
        data_final,
        fazendas: listProperties,
        maquinas: listMachinery,
        talhoes: listField
      };

    } else {
      const { data_inicial, data_final } = notWorkedForm;
      parameters = {
        data_inicial,
        data_final,
      };
    }

    // Consultar atividades
    const activity = new Activity(token);
    const Activities = await activity.SearchActivities(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setAlertWorked("Você não possui permissão para listar as atividades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlertWorked(true);
        return [];
      }
    });

    if (tipo === 'worked') {
      setDataWorkedActivity(Activities.atividade_pulverizacao);
    } else {
      setDataNotWorkedActivity(Activities.nao_trabalhado);
    }
  };

  /** Função para carregar a lista de propriedades.*/
  const LoadProperty = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0
    }

    let properties = new Property(token);
    await properties.SearchProperty(parameters).then(response => {
      if (response.length > 0) {
        const newDataProperty = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataProperty(newDataProperty);
      } else if (response.erro) {
        setAlertWorked("Você não possui permissão para listar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlertWorked(true);
      } else {
        return [];
      }
    });

  }, [module.modulo, token]);

  /** Função para carregar as máquinas com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadMachinery = async (propertyId) => {
    let machinery = new Machinery(token);

    await machinery.SearchPropertyMachinery(propertyId).then(response => {
      if (response.length > 0) {
        const newDataMachinery = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.numero + " - " + item.nome }))];
        return setDataMachinery(newDataMachinery);
      } else if (response.erro) {
        setAlertWorked("Você não possui permissão para listar o maquinário. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlertWorked(true);
      } else {
        return [];
      }
    });
  };

  /** Função para carregar os talhões com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadField = async (propertyId) => {

    let Field = new Property(token);
    await Field.SearchPropertyField(propertyId).then(response => {
      if (response.length > 0) {
        const newDataField = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.nome + " - " + item.fazenda_nome }))];
        return setDataField(newDataField);
      } else if (response.erro) {
        setAlertWorked("Você não possui permissão para listar os Talhões. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlertWorked(true);
      } else {
        return [];
      }
    });
  }

  /** Função para carregar os contratantes. */
  const LoadContractors = useCallback(async () => {
    let contractor = new Contractor(token);
    await contractor.SearchContractor('PULVERIZACAO').then(response => {
      if (response.length > 0) {
        const newDataContractor = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataContractor(newDataContractor);
      } else if (response.erro) {
        setAlertWorked("Você não possui permissão para listar os contratantes. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlertWorked(true);
      } else {
        return [];
      }
    });
  }, [token]);

  /** Change */

  /** Manipulador de eventos para seleção de contratantes no filtro.*/
  const ContractorChange = (event) => {
    if (event) {
      const isAllSelected = event.some((contractor) => contractor.id === 0);

      if (isAllSelected) {
        const contractorWithoutAll = event.filter((contractor) => contractor.id === 0);
        setWorkedForm({ ...workedForm, contratante: contractorWithoutAll });
      } else {
        const updateContractor = event.map((val) => ({ id: val.id, nome: val.nome }));
        setWorkedForm((prevData) => ({ ...prevData, contratante: updateContractor }));
      }
    }
  }

  /** Manipulador de eventos para seleção de propriedades no filtro. @param {Array} propriedades - Propriedades selecionadas.*/
  const PropertyChange = (event) => {
    if (event) {
      setWorkedForm({ ...workedForm, maquina: [], talhao: [] });
      setDataMachinery([]);
      setDataField([]);

      const isAllSelected = event.some((property) => property.id === 0);

      if (isAllSelected) {
        const value = 0;

        const propertiesWithoutAll = event.filter((property) => property.id === 0);
        setWorkedForm({ ...workedForm, talhao: [], propriedade: propertiesWithoutAll });
        LoadField(value);
        LoadMachinery(value);
      } else {
        const updateProperty = event.map((val) => ({ id: val.id, nome: val.nome }));
        const value = event.map((val) => (val.id));

        setWorkedForm((prevData) => ({ ...prevData, propriedade: updateProperty }));
        if (value.length > 0) {
          LoadMachinery(value);
          LoadField(value);
        }
      }
    }

  }

  /** Manipulador de eventos para seleção de máquinas no filtro. @param {Array} maquinario - Máquinas selecionadas.*/
  const MachineryChange = (event) => {
    if (event) {
      const isAllSelected = event.some((machinery) => machinery.id === 0);

      if (isAllSelected) {
        const machineryWithoutAll = event.filter((machinery) => machinery.id === 0);
        setWorkedForm({ ...workedForm, maquina: machineryWithoutAll });
      } else {
        const updateMachinery = event.map((val) => ({ id: val.id, nome: val.nome }));
        setWorkedForm((prevData) => ({ ...prevData, maquina: updateMachinery }));
      }
    }
  }

  /** Manipulador de eventos para seleção de talhões no filtro. @param {Array} talhoes - Talhões selecionados.*/
  const FieldChange = (event) => {
    if (event) {
      const isAllSelected = event.some((field) => field.id === 0);
      if (isAllSelected) {
        const fieldWithoutAll = event.filter((field) => field.id === 0);
        setWorkedForm({ ...workedForm, talhao: fieldWithoutAll });
      } else {
        const updateField = event.map((val) => ({ id: val.id, nome: val.nome }));
        setWorkedForm((prevData) => ({ ...prevData, talhao: updateField }));
      }
    }
  }

  /** W O R K E D */

  /** Manipulador de eventos para acionar o filtro. @param {Event} e - O evento de clique.*/
  const WorkedFilter = async (e) => {
    setOpenAlertWorked(false);

    if (
      workedForm.data_inicial &&
      workedForm.data_final &&
      workedForm.propriedade &&
      workedForm.propriedade.length > 0 &&
      workedForm.maquina &&
      workedForm.maquina.length > 0 &&
      workedForm.talhao &&
      workedForm.talhao.length > 0
    ) {
      await LoadActivity('worked');
    } else {
      setAlertWorked("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlertWorked(true);
    }
  };

  const WorkedActions = (id) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => openWorkedView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        <Tooltip title="Editar">
          <Button className="ListBtn" onClick={() => WorkedEdit(id)}><FontAwesomeIcon className="font-icons-list" icon={faPen} /></Button>
        </Tooltip>
        <Tooltip title="Deletar">
          <Button className="ListBtn" onClick={() => openWorkedDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  const WorkedEdit = async (id) => {

    const activities = new Activity(token);
    const activity = await activities.SearchWorkedActivities(id);

    const routes = module.rotas.find(element => element.tipo_rota === 'EDICAO' && element.referencia === 'atividade-trabalhada');
    if (routes) {
      navigate(`/gestor-atividades${routes.rota}`, { state: { tipo: 'edicao', data: activity } });
    } else {
      setAlertWorked("Não é possível editar o registro, tente novamente mais tarde !!");
      setOpenAlertWorked(true);
    }
  };

  const openWorkedView = (id) => {
    const dataView = dataWorkedActivity.find((val) => val.id === id);
    const { data_atividade, fazenda, talhao, maquina, cultura, qtd_hectares, descricao, aplicacoes, contratante } = dataView;
    const [day, month, year] = data_atividade.split("/");
    const convertedData = `${year}-${month}-${day}`;

    let application = [];

    aplicacoes.forEach((val, i) => {
      application.push({ id: val.id, nome: val.nome });
    })

    const content = () => {
      return (

        <Grid container justifyContent="center">

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Datas</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField type="date" className="disabled-textField" disabled value={convertedData} label="Data da Atividade" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Propriedade e Equipamento</Typography>
            </Grid>

            <Grid container spacing={1}>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled value={contratante} label="Contratante" />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled value={fazenda} label="Propriedade" />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled value={talhao} label="Talhão" />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled value={maquina} label="Tipo de Maquinário" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Aplicação</Typography>
            </Grid>

            <Grid container spacing={1}>

              <Grid item lg={5} md={12} sm={12} xs={12}>
                <AutocompleteSelect isMultiple={true} id="aplicacoesView" required={false} label="Tipo de Aplicação" data={application} value={application} disabled={true} />
              </Grid>

              <Grid item lg={5} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled label="Cultura" value={cultura} />
              </Grid>

              <Grid item lg={2} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled label="Qtd. Hectares" value={qtd_hectares}
                  InputProps={{
                    required: true,
                    endAdornment: <InputAdornment position="start">ha</InputAdornment>,
                  }}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4} marginBottom={8}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Descrição</Typography>
            </Grid>

            <Grid container spacing={2}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  id="descricao"
                  value={descricao}
                  label="Descreva a Atividade"
                  className="disabled-textField" disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    setTitleWorked("Listagem de Atividade de Pulverização - #" + id);
    setModalWorked(content);
    setFullscreen(true);
    setOpenWorkedModal(true);
  };

  const closeWorkedModal = () => {
    setOpenWorkedModal(false);
  };

  // Click botão deletar
  const openWorkedDelete = (id) => {
    setDeleteWorkedId(id);
    setTitleWorked("Deletar atividade");
    setDialogWorked("Você tem certeza que deseja excluir esta Atividade?");
    setOpenWorkedDialog(true);
  };

  // Click confirmar remoção
  const WorkedDeleteConfirmed = async () => {
    const user = User.searchUser();
    const token = user.token;

    const remove = new Activity(token);
    remove.DeleteWorkedActivities(deleteWorkedId);

    setWorkedActivity([]);
    setDataWorkedActivity([]);

    setOpenWorkedDialog(false);
  };

  // Click cancelar remoção
  const WorkedDeleteCanceled = () => {
    setOpenWorkedDialog(false);
  };

  /**Conteúdo da Tab Trabalhada*/
  const WorkedContent = () => {
    const headers = ['Data Atividade', 'Usuário', 'Propriedade', 'Talhão', 'Máquina', 'Hectares']

    return (
      <Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
          <Card className="card-form">
            <CardContent>
              <Grid container>
                <Typography variant="h6" className="titles">Filtre os dados</Typography>
              </Grid>

              <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <TextField
                    type="date"
                    id="data_inicial"
                    label="Data Inicial"
                    value={workedForm.data_inicial}
                    onChange={(event) => setWorkedForm({ ...workedForm, data_inicial: event.target.value })}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <TextField
                    type="date"
                    id="data_final"
                    label="Data Final"
                    value={workedForm.data_final}
                    onChange={(event) => setWorkedForm({ ...workedForm, data_final: event.target.value })}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="contratante"
                    label="Contratante"
                    isMultiple={true}
                    required={false}
                    disabled={false}
                    data={dataContractor}
                    value={workedForm.contratante}
                    onChange={ContractorChange}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="propriedade"
                    label="Propriedade"
                    isMultiple={true}
                    required={true}
                    disabled={false}
                    data={dataProperty}
                    value={workedForm.propriedade}
                    onChange={PropertyChange}
                  />
                </Grid>

                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <AutocompleteSelect
                    id="maquinario"
                    label="Maquinário"
                    isMultiple={true}
                    required={true}
                    disabled={false}
                    data={dataMachinery}
                    value={workedForm.maquina}
                    onChange={MachineryChange}
                  />
                </Grid>

                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <AutocompleteSelect
                    id="talhao"
                    label="Talhão"
                    isMultiple={true}
                    required={true}
                    disabled={false}
                    data={dataField}
                    value={workedForm.talhao}
                    onChange={FieldChange}
                  />
                </Grid>

                <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                  <Tooltip title="Filtrar">
                    <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={WorkedFilter}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container justifyContent="center" >
                <Alertavel title="Atenção" type="warning" open={openAlertWorked} content={alertWorked} />

              </Grid>

              <ConfirmDialog
                title={titleWorked}
                text={"Deletar"}
                open={openWorkedDialog}
                content={dialogWorked}
                onClose={WorkedDeleteCanceled}
                onConfirm={WorkedDeleteConfirmed}
              />

              <ResponsiveDialog
                title={titleWorked}
                content={modalWorked}
                onClose={closeWorkedModal}
                openModal={openWorkedModal}
                isFullscreen={fullscreen}
              />

            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} id="dataTable">
          <Card className="card-form">
            <CardContent>
              <Tables headers={headers} rows={workedActivity} actions={WorkedActions} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  /** N O T - W O R K E D */

  /** Manipulador de eventos para acionar o filtro. @param {Event} e - O evento de clique.*/
  const NotWorkedFilter = async (e) => {
    setNotWorkedActivity([]);

    setOpenAlertNotWorked(false);
    if (notWorkedForm.data_inicial && notWorkedForm.data_final) {
      await LoadActivity('notWorked');
    } else {
      setAlertNotWorked("É necessário informar a Data Inicial e a Data Final para fazer a filtragem");
      setOpenAlertNotWorked(true);
    }
  };

  const NotWorkedActions = (id) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => openNotWorkedView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        <Tooltip title="Editar">
          <Button className="ListBtn" onClick={() => notWorkedEdit(id)}><FontAwesomeIcon className="font-icons-list" icon={faPen} /></Button>
        </Tooltip>
        <Tooltip title="Deletar">
          <Button className="ListBtn" onClick={() => openNotWorkedDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  const notWorkedEdit = async (id) => {

    const activities = new Activity(token);
    const notWorkedActivity = await activities.SearchNotWorkedActivities(id);
    const routes = module.rotas.find(element => element.tipo_rota === 'EDICAO' && element.referencia === 'atividade-nao-trabalhada');
    if (routes) {
      navigate(`/gestor-atividades${routes.rota}`, { state: { tipo: 'edicao', data: notWorkedActivity } });
    } else {
      setAlertWorked("Não é possível editar o registro, tente novamente mais tarde !!");
      setOpenAlertWorked(true);
    }
  };

  const openNotWorkedView = (id) => {
    const dataView = dataNotWorkedActivity.find((val) => val.id === id);
    const { descricao, justificativas, data_atividade } = dataView;

    const [day, month, year] = data_atividade.split("/");
    const convertedData = `${year}-${month}-${day}`;

    let justifications = [];

    justificativas.forEach((val, i) => {
      justifications.push({ id: val.id, nome: val.nome });
    })

    const content = () => {
      return (

        <Grid container justifyContent="center">

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Datas</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField type="date" className="disabled-textField" disabled value={convertedData} label="Data da Atividade" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Aplicação</Typography>
            </Grid>

            <Grid container spacing={1}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AutocompleteSelect isMultiple={true} required={false} id="justificativasView" label="Justificativas" data={justifications} value={justifications} disabled={true} />
              </Grid>

            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4} marginBottom={8}>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Descrição</Typography>
            </Grid>

            <Grid container spacing={2}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  id="descricao"
                  value={descricao}
                  label="Descreva a Atividade"
                  className="disabled-textField" disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }


    setTitleNotWorked("Listagem de Atividade Sem Pulverização - #" + id);
    setModalNotWorked(content);
    setFullscreen(true);
    setOpenNotWorkedModal(true);
  };

  const closeNotWorkedModal = () => {
    setOpenNotWorkedModal(false);
  };

  // Click botão deletar
  const openNotWorkedDelete = (id) => {
    setDeleteNotWorkedId(id);
    setTitleNotWorked("Deletar atividade");
    setDialogNotWorked("Você tem certeza que deseja excluir esta Atividade?");
    setOpenNotWorkedDialog(true);
  };

  // Click confirmar remoção
  const NotWorkedDeleteConfirmed = async () => {
    const user = User.searchUser();
    const token = user.token;

    const remove = new Activity(token);
    remove.DeleteNotWorkedActivities(deleteNotWorkedId);

    setNotWorkedActivity([]);
    setDataNotWorkedActivity([]);

    setOpenNotWorkedDialog(false);
  };

  // Click cancelar remoção
  const NotWorkedDeleteCanceled = () => {
    setOpenNotWorkedDialog(false);
  };

  /**Conteúdo da Tab Não Trabalhada*/
  const notWorkedContent = () => {
    const headers = ['Data Atividade', 'Usuário', 'Justificativas']
    return (
      <Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
        <Card className="card-form">
          <CardContent>
            <Grid container>
              <Typography variant="h6" className="titles">Filtre os dados</Typography>
            </Grid>

              <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

                <Grid item lg={5} md={4} sm={4} xs={12}>
                  <TextField
                    type="date"
                    id="data_inicial"
                    label="Data Inicial"
                    value={notWorkedForm.data_inicial}
                    onChange={(event) => setNotWorkedForm({ ...notWorkedForm, data_inicial: event.target.value })}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                </Grid>

                <Grid item lg={5} md={4} sm={4} xs={12}>
                  <TextField
                    type="date"
                    id="data_final"
                    label="Data Final"
                    value={notWorkedForm.data_final}
                    onChange={(event) => setNotWorkedForm({ ...notWorkedForm, data_final: event.target.value })}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                </Grid>

                <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                  <Tooltip title="Filtrar">
                    <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={NotWorkedFilter}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container justifyContent="center" >
                <Alertavel title="Atenção" type="warning" open={openAlertNotWorked} content={alertNotWorked} />

                <ConfirmDialog
                  title={titleNotWorked}
                  text={"Deletar"}
                  open={openNotWorkedDialog}
                  content={dialogNotWorked}
                  onClose={NotWorkedDeleteCanceled}
                  onConfirm={NotWorkedDeleteConfirmed}
                />

                <ResponsiveDialog
                  title={titleNotWorked}
                  content={modalNotWorked}
                  onClose={closeNotWorkedModal}
                  openModal={openNotWorkedModal}
                  isFullscreen={fullscreen}
                />
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} id="dataTable">
          <Card className="card-form">
            <CardContent>
              <Tables headers={headers} rows={notWorkedActivity} actions={NotWorkedActions} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    )
  }

  /** Formatação dos dados Trabalhados e não Trabalhados 
   * @param {Object} data - Dados Retornados 
   * @param {boolean} isWorked - Variável para identificar se é trabalhado ou não  
   * */

  const formatActivityData = (data, isWorked) => {
    return data.map((val) => {
      const activityData = {
        id: val.id,
        data_atividade: val.data_atividade,
        user: val.username,
      };

      if (isWorked) {
        activityData.propriedade = val.fazenda;
        activityData.talhao = val.talhao;
        activityData.maquina = val.maquina;
        activityData.hectares = val.qtd_hectares;
      } else {
        const JustificationsName = val.justificativas.map((justifications) => {
          const formattedName = justifications.nome.charAt(0).toUpperCase() + justifications.nome.slice(1).toLowerCase();
          return formattedName;
        });

        activityData.justificativas = JustificationsName.join(', ');
      }

      return activityData;
    });
  };

  useEffect(() => {
    LoadProperty();
    LoadContractors();
  }, [LoadProperty, LoadContractors]);

  useEffect(() => {
    const formatWorkedActivitiesData = () => {
      const formattedData = formatActivityData(dataWorkedActivity, true);
      setWorkedActivity(formattedData);
    };

    formatWorkedActivitiesData();
  }, [dataWorkedActivity]);

  useEffect(() => {
    const formatNotWorkedActivitiesData = () => {
      const formattedData = formatActivityData(dataNotWorkedActivity, false);
      setNotWorkedActivity(formattedData);
    };

    formatNotWorkedActivitiesData();
  }, [dataNotWorkedActivity]);

  /** Dados das abas a serem exibidas. @type {Array} */
  const dataTabs = [
    { label: 'Atividade de Pulverização', content: WorkedContent() },
    { label: 'Atividade sem Pulverização', content: notWorkedContent() }
  ]

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Listagem de Atividades - Gestor de Atividades</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">Listagem de Atividades</Typography>
      </Grid>

      <Grid container spacing={2} item lg={8} md={12} sm={12} xs={12} justifyContent="center">
        <CustomTabs data={dataTabs} />
      </Grid>
    </Grid>
  );
}

export default ListActivities;