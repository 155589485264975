import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Tooltip, TextField, Typography } from '@mui/material';

import Alertavel from '../../../Components/Alertavel';
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { User } from '../../../Models/User';
import { Company } from '../../../Models/Organization/Company';
import { Machinery } from '../../../Models/Machinery/Machinery';
import { Property } from '../../../Models/Organization/Property';
import { Activity } from '../../../Models/Activities/Activities';
import { Contractor } from '../../../Models/Organization/Contractor';

import { SimpleColumnChart, PieChart, TreeMapChart, SimpleLineDateChart } from '../../../Components/ApexCharts';

import { dataTypeActivities, dataPilotStatus } from '../constants';
import { getCurrentDate, showLoading } from '../../../Services/globalFunction';

import "../../../App.css";

const Dashboard = ({ module }) => {
  const title = "Dashboard";
  const [dashboardContent, setDashboardContent] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  
  /** Estados para exibir alertas*/
  const [openAlert, setOpenAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState('');

  /** Estados para armazenar dados de talhões, máquinas e propriedades*/
  const [dataField, setdataField] = useState([]);
  const [dataPilot, setDataPilot] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataMachinery, setdataMachinery] = useState([]);
  const [dataContractor, setDataContractor] = useState([]);

  /** Estado para armazenar os dados do formulário. */
  const [relForm, setRelForm] = useState({
    data_inicial: getCurrentDate(0, 30, 'subtrai'),
    data_final: getCurrentDate(0, 0, ''),
    tipo_pulverizacao: null,
    situacao_piloto: 'ATIVOS',
    propriedade: [],
    tipo: null,
    maquina: [],
    talhao: [],
    piloto: [],
    contratantes: []
  });

  /** Obter o usuário e o token */
  const user = User.searchUser();
  const token = user.token;

  /** Função para carregar a lista de propriedades.*/
  const LoadProperty = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0
    }

    let properties = new Property(token);
    await properties.SearchProperty(parameters).then(response => {
      if (response.length > 0) {
        const newdataProperty = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataProperty(newdataProperty);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, [module.modulo, token]);

  /** Função para carregar as máquinas com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadMachinery = async (propertyId) => {
    let machinery = new Machinery(token);

    await machinery.SearchPropertyMachinery(propertyId).then(response => {
      if (response.length > 0) {
        const newDataMachinery = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.numero + " - " + item.nome }))];
        return setdataMachinery(newDataMachinery);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar o maquinário. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  };

  /** Função para carregar os pilotos. */
  const LoadPilots = useCallback(async (pilotStatus) => {
    let pilots = new Company(token);

    await pilots.SearchPilot(user.empresa_id ?? 0, pilotStatus?pilotStatus:relForm.situacao_piloto).then(response => {
      if (response.length > 0) {
        let formatted = response.map((pilot) => {
          return { 'id': pilot.id, 'nome': (pilot.first_name ?? pilot.username) + ' ' + pilot.last_name ?? '' }
        })

        let newData = [{ id: 0, nome: 'Todos' }, ...formatted];
        if (response.length === 1) {
          newData = formatted;
        }

        
        return setDataPilot(newData);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os pilotos. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, [token, user.empresa_id, relForm.situacao_piloto]);

  /** Função para carregar os talhões com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadField = async (propertyId) => {
    let fields = new Property(token);
    await fields.SearchPropertyField(propertyId).then(response => {
      if (response.length > 0) {
        const newdataField = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.nome + " - " + item.fazenda_nome }))];
        return setdataField(newdataField);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os Talhões. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }

  const LoadContractors = useCallback(async () => {
    let contractor = new Contractor(token);
    await contractor.SearchContractor('PULVERIZACAO').then(response => {
      if (response.length > 0) {
        const newDataContractor = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataContractor(newDataContractor);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os contratantes. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, [token]);

  /** Manipulador de eventos para seleção de propriedades no filtro. @param {Array} event - Propriedades selecionadas.*/
  const PropertyChange = (event) => {
    if (event) {

      setRelForm({ ...relForm, maquina: [], talhao: [] });
      setdataMachinery([]);
      setdataField([]);

      const isAllSelected = event.some((property) => property.id === 0);

      if (isAllSelected) {
        const value = [0];

        const propertiesWithoutAll = event.filter((property) => property.id === 0);
        setRelForm({ ...relForm, talhao: [], propriedade: propertiesWithoutAll });
        LoadMachinery(value);
        LoadField(value);
      } else {
        const updateProperty = event.map((val) => ({ id: val.id, nome: val.nome }));
        const value = event.map((val) => (val.id));

        setRelForm((prevData) => ({ ...prevData, propriedade: updateProperty }));

        if (value.length > 0) {
          LoadMachinery(value);
          LoadField(value);
        }
      }
    }
  }

  /** Manipulador de eventos para seleção de máquinas no filtro. @param {Array} event - Máquinas selecionadas.*/
  const MachineryChange = (event) => {
    if (event) {

      const isAllSelected = event.some((machinery) => machinery.id === 0);

      if (isAllSelected) {
        const machineryWithoutTodos = event.filter((machinery) => machinery.id === 0);
        setRelForm({ ...relForm, maquina: machineryWithoutTodos });
      } else {
        const updateMachinery = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, maquina: updateMachinery }));
      }
    }
  }

  const ContractorChange = (event) => {
    if (event) {
      const isAllSelected = event.some((field) => field.id === 0);

      if (isAllSelected) {
        const WithoutAll = event.filter((field) => field.id === 0);
        setRelForm({ ...relForm, contratantes: WithoutAll });
      } else {
        const update = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, contratantes: update }));
      }
    }
  }

  /** Manipulador de eventos para seleção de status de piloto no filtro. @param {Array} event Status de piloto selecionado.*/
  const PilotStatusChange = (event) => {
    setRelForm({ ...relForm, piloto: []});
    setDataPilot([]);
    LoadPilots(event?event.id:null);
  }

  /** Manipulador de eventos para seleção de máquinas no filtro. @param {Array} event - Pilotos selecionadas.*/
  const PilotChange = (event) => {
    if (event) {

      const isAllSelected = event.some((pilot) => pilot.id === 0);

      if (isAllSelected) {
        const pilotWithoutAll = event.filter((pilot) => pilot.id === 0);
        setRelForm({ ...relForm, piloto: pilotWithoutAll });
      } else {
        const updatePilot = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, piloto: updatePilot }));
      }
    }
  }

  /** Manipulador de eventos para seleção de talhões no filtro. @param {Array} event - Talhões selecionados.*/
  const FieldChange = (event) => {
    if (event) {
      const isAllSelected = event.some((field) => field.id === 0);

      if (isAllSelected) {
        const fieldWithoutAll = event.filter((field) => field.id === 0);
        setRelForm({ ...relForm, talhao: fieldWithoutAll });
      } else {
        const updateField = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, talhao: updateField }));
      }
    }
  }

  const TypeActivityChange = (event) => {
    if (event) {
      setRelForm({ ...relForm, tipo_pulverizacao: event.id });
    }
  }

  const checkForm = () => {
    return relForm.data_inicial &&
      relForm.data_final &&
      relForm.propriedade &&
      relForm.propriedade.length > 0 &&
      relForm.maquina &&
      relForm.maquina.length > 0 &&
      relForm.talhao &&
      relForm.talhao.length > 0 &&
      relForm.contratantes &&
      relForm.contratantes.length > 0 &&
      relForm.piloto &&
      relForm.piloto.length > 0 &&
      (relForm.tipo_pulverizacao || relForm.tipo_pulverizacao === 0)
  }

  const ExecuteDashboard = async () => {
    if (
      checkForm()
    ) {
      setOpenAlert(false);

      let { data_inicial, data_final, propriedade, maquina, talhao, piloto, contratantes, tipo, tipo_pulverizacao } = relForm;

      let listProperties = propriedade.map((val) => val.id);
      let listMachinery = maquina.map((val) => val.id);
      let listFields = talhao.map((val) => val.id);
      let listPilots = piloto.map((val) => val.id);
      let listContractors = contratantes.map((val) => val.id);

      let parameters = {
        tipo,
        data_inicial,
        data_final,
        fazendas: listProperties.join(','),
        maquinas: listMachinery.join(','),
        talhoes: listFields.join(','),
        pilotos: listPilots.join(','),
        contratantes: listContractors.join(','),
        tipo_pulverizacao
      };

      
      await LoadDashboard(false, parameters);
    } else {
      setContentAlert("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlert(true);
    }
  }

  const LoadDashboard = useCallback(async (execute_initial, parameters) => {
    setDashboardContent('')

    if (execute_initial) {
      parameters = {
        tipo: 0,
        data_inicial: getCurrentDate(0, 30, 'subtrai'),
        data_final: getCurrentDate(0, 0, ''),
        fazendas: '0',
        maquinas: '0',
        talhoes: '0',
        pilotos: '0',
        tipo_pulverizacao: 0
      }
    }

    showLoading(true)
    const activity = new Activity(token);
    const dados = await activity.GenerateActivitiesDashboard(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Um erro aconteceu. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return {};
      }
    })

    showLoading(false)

    if(dados.possui_dados) {
      setOpenAccordion(false)
      montarIndicadores(dados)
    } else if(!execute_initial) {
      setDashboardContent(
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center">

          <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
            Não existe dados para os filtros selecionados!
          </Grid>
          
        </Grid>)
    }
  }, [token]);

  const montarIndicadores = dados => {

    //----Preparação dos dados de aplicações por piloto (Desempenho de Aplicadores)
    let categories_aplicacoes_por_piloto = dados.aplicacoes_por_piloto.map(obj => {
      let nome = obj.user__first_name !== ''? `${obj.user__first_name} ${obj.user__last_name}`: obj.user__name
      return nome
    })
    let data_aplicacoes_por_piloto = dados.aplicacoes_por_piloto.map(obj => obj.total_qtd)

    //----Preparação dos dados de aplicações por tipo de aplicação (Quantia de Hectares por Tipo de Aplicação)
    let labels_aplicacoes_por_tipo_aplicacao = dados.aplicacoes_por_tipo_aplicacao.reduce((filtered, obj) => {
      if (obj.aplicacoes__nome !== null) {
        filtered.push(obj.aplicacoes__nome)
      }
      return filtered
    }, [])

    let data_aplicacoes_por_tipo_aplicacao = dados.aplicacoes_por_tipo_aplicacao.reduce((filtered, obj) => {
      if (obj.aplicacoes__nome !== null) {
        filtered.push(obj.total_qtd)
      }
      return filtered
    }, [])


    //----Preparação dos dados de aplicações por fazenda/talhão
    let fazendas = {}
    dados.aplicacoes_por_fazenda_talhao.forEach(obj => {
      if (!(obj.talhao__fazenda__id in fazendas)) {
        fazendas[obj.talhao__fazenda__id] = {'id': 'fazenda_' + obj.talhao__fazenda__id, 'nome': obj.talhao__fazenda__nome, 'dados': [], 'total': 0}
      }

      fazendas[obj.talhao__fazenda__id]['dados'].push({
        'x': obj.talhao__nome, 'y': obj.total_qtd
      })

      fazendas[obj.talhao__fazenda__id]['total'] += obj.total_qtd
    })
    
    var grid_fazendas = [];
    var dados_aplicacoes_por_fazendas = [];

    for(let f in fazendas) {
      let fazenda = fazendas[f]
      dados_aplicacoes_por_fazendas.push({'x': fazenda.nome, 'y': fazenda.total})

      fazenda.nome = `${fazenda.nome} - ${fazenda.total.toLocaleString('pt-br', {minimumFractionDigits: 2})} hectares`

      grid_fazendas.push(
        <Grid item lg={Object.keys(fazendas).length === 1? 12: 6} md={12} sm={12} xs={12} mb={4}>
          <TreeMapChart id={fazenda.id} title={fazenda.nome} data={fazenda.dados} />
        </Grid>
      )
    }

    //----Preparação dos dados de aplicações por período
    let meses = ['Jan','Fev','Mar','Abr','Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    let obj_aplicacoes_por_periodo = {}

    dados.aplicacoes_por_periodo.forEach(obj => {
      let t = obj.data_atividade.split('-')
      let data = `${t[2]}/${t[1]}/${t[0]}`
      let mes_ano = `${meses[parseInt(t[1]) - 1]}/${t[0]}`

      if (!(mes_ano in obj_aplicacoes_por_periodo)) {
        obj_aplicacoes_por_periodo[mes_ano] = {
          'total': 0, 'datas': [], 'valores': []
        }
      }

      obj_aplicacoes_por_periodo[mes_ano]['datas'].push(data)
      obj_aplicacoes_por_periodo[mes_ano]['valores'].push(obj.total_qtd)
      obj_aplicacoes_por_periodo[mes_ano]['total'] += obj.total_qtd
    })

    let labels_aplicacoes_por_periodo = []
    let data_aplicacoes_por_periodo = []
    let tam = Object.keys(obj_aplicacoes_por_periodo).length

    for(let key in obj_aplicacoes_por_periodo) {
      let obj_key = obj_aplicacoes_por_periodo[key]
      if (tam === 1) {
        labels_aplicacoes_por_periodo = obj_key['datas']
        data_aplicacoes_por_periodo = obj_key['valores']
      } else {
        labels_aplicacoes_por_periodo.push(key)
        data_aplicacoes_por_periodo.push(obj_key.total.toFixed(2))
      }
    }

    setDashboardContent(
      <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center">
        <br />

        <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>

          <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

            <Grid item lg={6} md={12} sm={12} xs={12} mb={4}>
              <SimpleColumnChart id="aplicacoes_por_piloto" title="Desempenho de Aplicadores"
                                categories={categories_aplicacoes_por_piloto} 
                                series={[{'name': 'Hectares', 'data': data_aplicacoes_por_piloto}]}
              />
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12} mb={4}>
              <PieChart id="aplicacoes_por_tipo_aplicacao" title="Quantia de Hectares por Tipo de Aplicação"
                                labels={labels_aplicacoes_por_tipo_aplicacao} 
                                series={data_aplicacoes_por_tipo_aplicacao}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
              <SimpleLineDateChart id="aplicacoes_por_periodo" title="Aplicações no período"
                                categories={labels_aplicacoes_por_periodo} 
                                series={[{data:data_aplicacoes_por_periodo}]}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
              <TreeMapChart id="aplicacoes_por_fazendas" title="Aplicações por fazendas" data={dados_aplicacoes_por_fazendas} />
            </Grid>

            {grid_fazendas}
            
          </Grid>

        </Grid>

      </Grid>
    )

  }

  useEffect(() => {
    LoadProperty();
    LoadPilots();
    LoadContractors();

    LoadDashboard(true, {});
  }, [LoadPilots, LoadProperty, LoadDashboard, LoadContractors]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Gestor de Atividades</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>

        <Accordion expanded={openAccordion} onChange={(e) => { setOpenAccordion(!openAccordion)}}>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
            <Typography variant="h6" className="titles">Filtre os dados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  label="Data Inicial"
                  value={relForm.data_inicial}
                  onChange={(event) => setRelForm({ ...relForm, data_inicial: event.target.value })}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  label="Data Final"
                  value={relForm.data_final}
                  onChange={(event) => setRelForm({ ...relForm, data_final: event.target.value })}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="propriedade"
                  label="Propriedade"
                  isMultiple={true}
                  required={true}
                  disabled={false}
                  data={dataProperty}
                  value={relForm.propriedade}
                  onChange={PropertyChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="talhao"
                  label="Talhão"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataField}
                  value={relForm.talhao}
                  onChange={FieldChange}
                />
              </Grid>
              
              <Grid item lg={6} md={5} sm={6} xs={12}>
                <AutocompleteSelect
                  id="situacao_piloto"
                  label="Situação do piloto"
                  required={true}
                  isMultiple={false}
                  disabled={false}
                  data={ dataPilotStatus }
                  value={ relForm.situacao_piloto }
                  onChange={PilotStatusChange}
                />
              </Grid>

              <Grid item lg={6} md={7} sm={6} xs={12}>
                <AutocompleteSelect
                  id="piloto"
                  label="Pilotos"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataPilot}
                  value={relForm.piloto}
                  onChange={PilotChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AutocompleteSelect
                  id="maquinario"
                  label="Maquinário"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataMachinery}
                  value={relForm.maquina}
                  onChange={MachineryChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AutocompleteSelect
                  id="contratante"
                  label="Contratante"
                  isMultiple={true}
                  required={false}
                  disabled={false}
                  data={dataContractor}
                  value={relForm.contratantes}
                  onChange={ContractorChange}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AutocompleteSelect
                  id="tipo_pulverizacao"
                  label="Tipo de pulverização"
                  required={true}
                  isMultiple={false}
                  disabled={false}
                  data={dataTypeActivities}
                  value={relForm.tipo_pulverizacao}
                  onChange={TypeActivityChange}
                />
              </Grid>

              <Grid item lg={8} md={12} sm={12} xs={12} mb={1} textAlign="center">
                <Tooltip title="Filtrar">
                  <Button className="successBtn" variant="contained" onClick={ExecuteDashboard} >Executar</Button>
                </Tooltip>
              </Grid>

            </Grid>
          </AccordionDetails>
        </Accordion>

      </Grid>


      {dashboardContent}

    </Grid>
  );
}

export default Dashboard;