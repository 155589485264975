import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { InputAdornment } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { User } from "../../../Models/User";
import { Culture } from '../../../Models/Essential/Culture';
import { ItemGroup } from '../../../Models/Inventory/ItemGroup';
import { Property } from "../../../Models/Organization/Property";
import { AgronomicPrescription} from '../../../Models/AgronomicPrescription/AgronomicPrescription';
import { Contractor } from '../../../Models/Organization/Contractor';

import Alertavel from '../../../Components/Alertavel';
import ConfirmDialog from '../../../Components/Dialog/Confirm';
import ResponsiveDialog from '../../../Components/Dialog/Dialog';
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { ErrorToStringFix, showLoading } from "../../../Services/globalFunction.js";

import { dataUnit, machinery } from '../constants';

import "../../../App.css";

const AddAgronomicPrescription = ({ module }) => {

  const [title, setTitle] = useState("Cadastro de Receitas");
  const [cards, setCards] = useState([{ id: 0 }]);
  const [btnReset, setBtnReset] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [contentAlert, setContentAlert] = useState("");
  const [contentModal, setContentModal] = useState("");
  const [contentDialog, setContentDialog] = useState("");

  // Dados a serem carregados nos selects
  const [dataItem, setDataItem] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [dataContratante, setDataContratante] = useState([]);
  const [dataField, setDataField] = useState([]);
  const [dataCulture, setDataCulture] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataMachinery, setDataMachinery] = useState([]);

  const [dataAdornment, setAdornment] = useState({});

  // Dados Selecionados e ou inseridos nos campos
  const [formData, setFormData] = useState({
    id: "",
    obs: "",
    area: "",
    contratante: null,
    fazenda: null,
    cultura: null,
    umidade_ar: "",
    data_final: "",
    hora_final: "",
    data_inicial: "",
    hora_inicial: "",
    temperatura_max: "",
    temperatura_min: "",
    velocidade_vento: "",
    tipo_maquinario: "",
    capacidade_tanque: 0,
    vazao_hectare: 0,
    talhoes: []
  });

  const [cardData, setCardData] = useState({
    id: "",
    grupo: [],
    item: [],
    dose_tanque: 0,
    dose_hectare: 0,
    ordem_adicao: "",
    unidade_item: ""
  });

  // Dados Selecionados nos cards de produtos
  const [deleteItemId, setDeleteItemId] = useState("");

  const user = User.searchUser();
  const token = user.token;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const LoadFields = useCallback(async (propertyId) => {
    let Field = new Property(token);
    let Fields = await Field.SearchPropertyInfo(propertyId).then(response => {
      if (!response.erro) {
        return response.talhoes;
      } else {
        setContentAlert("Você não possui permissão para buscar os talhões da propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataField(Fields);
  }, [token])

  const LoadFarm = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0,
      ativa: 'S'
    }

    let Farm = new Property(token);
    let Properties = await Farm.SearchProperty(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataProperty(Properties);
  }, [module.modulo, token]);

  const LoadCulture = useCallback(async () => {
    let Cultures = new Culture(token);
    let culturesResearched = await Cultures.SearchCulture().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar as culturas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataCulture(culturesResearched);
  }, [token]);

  const LoadGroups = useCallback(async () => {
    let Group = new ItemGroup(token);
    let Groups = await Group.SearchGroup().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar os grupos. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataGroup(Groups);
  }, [token]);

  const LoadItem = useCallback(async (groupId, index) => {
    const Item = new ItemGroup(token);
    const Product = await Item.SearchItemGroup(groupId).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar os produtos. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    let dataToUpdate = { [index]: Product };
    setDataItem((prevData) => ({ ...prevData, ...dataToUpdate }));
  }, [token]);

  const LoadContractors = useCallback(async () => {
    let Contractors = new Contractor(token);
    let contractors = await Contractors.SearchContractor('RECEITA').then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar os Contratantes. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataContratante(contractors);
  }, [token])

  const LoadMachinery = useCallback(() => {
    setDataMachinery(machinery);
  }, []);

  const LoadQuantify = useCallback(() => {
    let newQuantidades = {};

    cards.forEach((val) => {
      const id = val.id;
      let unidade_medida = cardData.unidade_item[val.id];

      if (formData.capacidade_tanque !== "" && formData.vazao_hectare !== "" && cardData.dose_hectare[val.id] !== "" && unidade_medida !== "") {

        let VolHectare = parseFloat(cardData.dose_hectare[val.id]);
        let VazaoMaq = parseFloat(formData.vazao_hectare);
        let CapTanque = parseFloat(formData.capacidade_tanque);

        let quantidade = (VolHectare / VazaoMaq) * CapTanque;

        const unidadeMedidaMap = {
          'ML': { adornment: 'Litros', factor: 0.001 },
          'GRAMA': { adornment: 'Quilogramas', factor: 0.001 },
          'L': { adornment: 'Litros', factor: 1 },
        };

        const medidaInfo = unidadeMedidaMap[unidade_medida];

        if (medidaInfo) {
          setAdornment((prevData) => ({ ...prevData, [id]: medidaInfo.adornment }));
          quantidade *= medidaInfo.factor;
        } else {
          setAdornment((prevData) => ({ ...prevData, [id]: 'Quilogramas' }));
        }

        newQuantidades[id] = parseFloat(quantidade);

      }
    });

    setCardData((prevQuantidade) => ({ ...prevQuantidade, dose_tanque: newQuantidades }));

  }, [formData.capacidade_tanque, formData.vazao_hectare, cardData.dose_hectare, cardData.unidade_item, cards]);

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'receitas');
    navigate(`/receituario-agronomico${routes.rota}`);
    setTitle("Cadastro de Receitas");

    setFormData({
      id: "",
      obs: "",
      area: "",
      fazenda: null,
      cultura: null,
      umidade_ar: "",
      data_final: "",
      hora_final: "",
      data_inicial: "",
      hora_inicial: "",
      temperatura_max: "",
      temperatura_min: "",
      velocidade_vento: "",
      tipo_maquinario: "",
      capacidade_tanque: 0,
      vazao_hectare: 0,
      talhoes: []
    });

    setCardData({ id: "", grupo: [], item: [], dose_tanque: 0, dose_hectare: 0, ordem_adicao: "", unidade_item: "" });
    setBtnReset('');
  }, [module.rotas, navigate]);

  /* Change Campos */

  const handleGrupoChange = async (event, index) => {
    if (event) {
      setDataItem((prevData) => ({ ...prevData, [index]: [] }));
      setCardData((prevData) => ({ ...prevData, grupo: { ...prevData['grupo'], [index]: event.id } }));
      await LoadItem(event.id, index);
      setCardData((prevData) => ({ ...prevData, item: { ...prevData['item'], [index]: [] } }));
    }
  };

  const handleCardChange = (event, index, field) => {
    if ((field === 'item' && event) || (field === 'unidade_item' && event)) {
      setCardData((prevData) => ({ ...prevData, [field]: { ...prevData[field], [index]: event.id } }));
    } else if (event) {
      const { value } = event.target;
      setCardData((prevData) => ({ ...prevData, [field]: { ...prevData[field], [index]: value } }));
    }
  };

  const handleFarmChange = async (event) => {
    if (event) {
      setFormData({ ...formData, fazenda: event.id, talhoes: [] });
      LoadFields(event.id);
    }
  }

  const handleFieldChange = (event) => {
    if (event) {
      let updateField = [];
      const maxLength = event.reduce((total, val) => total + parseFloat(val.tamanho??0), 0);
      event.forEach((val, i) => {
        updateField.push({ id: val.id, nome: val.nome, tamanho: val.tamanho??0 });
      });
      setFormData((prevData) => ({ ...prevData, talhoes: updateField, area: maxLength }));
    }
  };

  const handleAddCard = () => {
    const nextId = cards.length > 0 ? cards[cards.length - 1].id + 1 : 1;
    setCards([...cards, { id: nextId }]);
  };

  const handleRemoveCard = (event, index) => {
    const dataId = event.currentTarget.getAttribute("data-id");

    if (dataId) {
      setDeleteItemId({ id: dataId, index });
      setTitle("Deletar Item");
      setContentDialog("Você tem certeza que deseja excluir este Item?");
      setOpenDialog(true);
    } else {
      delete cardData.item[index];
      delete cardData.dose_tanque[index];
      delete cardData.dose_hectare[index];
      delete cardData.ordem_adicao[index];
      delete cardData.unidade_item[index];

      const updatedCards = cards.filter((card) => card.id !== index);
      setCards(updatedCards);
    }
  };

  const handleRemoveConfirm = async () => {
    const user = User.searchUser();
    const token = user.token;

    const id = deleteItemId.id;
    const index = deleteItemId.index;

    const item = new AgronomicPrescription(token);
    item.DeleteAgronomicPrescriptionItem(id)

    delete cardData.id[index];
    delete cardData.item[index];
    delete cardData.dose_tanque[index];
    delete cardData.dose_hectare[index];
    delete cardData.ordem_adicao[index];
    delete cardData.unidade_item[index];

    const updatedCards = cards.filter((card) => card.id !== index);
    setCards(updatedCards);

    setOpenDialog(false);
  };

  const handleRemoveCancel = () => {
    setOpenDialog(false);
  };

  /** @param {*} card Verifica se os dados dos cards são válidos*/
  const isCardInvalid = (card) => {
    return (
      !cardData.item[card.id] ||
      !cardData.dose_tanque[card.id] ||
      !cardData.unidade_item[card.id] ||
      !cardData.dose_hectare[card.id] ||
      !cardData.ordem_adicao[card.id]
    );
  };

  /** Verifica se a ordem de cauda dos produtos não é igual*/
  const hasDuplicateCardOrder = () => {
    const cardOrders = cards.map((card) => cardData.ordem_adicao[card.id]);
    return cardOrders.some((order, index) => cardOrders.indexOf(order) !== index);
  };

  /** Verifica se a Temperatura é válida*/
  const isValidTemperature = () => {
    const minTemp = parseInt(formData.temperatura_min);
    const maxTemp = parseInt(formData.temperatura_max);
    return (
      minTemp < -20 || maxTemp > 70 || minTemp > maxTemp
    );
  };

  /** Verifica se a Umidade do Ar é válida*/
  const isValidAirHumidiry = () => {
    const umidadeAr = parseInt(formData.umidade_ar);
    return umidadeAr < 0 || umidadeAr > 100;
  };

  /** Verifica se a Velocidade do Vento é válida*/
  const isValidAirVelocity = () => {
    const velVento = parseInt(formData.velocidade_vento);
    return velVento < 0 || velVento > 75;
  };

  /** Verifica se a Data Inicial é maior que a final*/
  const isValidDataIniEnd = () => {
    return formData.data_inicial > formData.data_final;
  };

  /** Verifica se a Hora Inicial é maior que a final*/
  const isValidIniEndHour = () => {
    return formData.hora_inicial === formData.hora_final;
  };

  const validateForm = () => {
    const requiredFields = [
      'area',
      'data_final',
      'data_inicial',
      'fazenda',
      'umidade_ar',
      'talhoes',
      'cultura',
      'hora_inicial',
      'temperatura_max',
      'temperatura_min',
      'hora_final',
      'velocidade_vento',
      'capacidade_tanque',
      'tipo_maquinario',
      'vazao_hectare',
    ];

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field] && formData[field] !== 0);
    console.log(formData)

    const isAnyCardInvalid = cards.some(isCardInvalid);

    if (isAnyFieldInvalid || isAnyCardInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    if (hasDuplicateCardOrder()) {
      return "Os produtos não podem ter a mesma ordem de adição.";
    }

    if (isValidTemperature()) {
      return "Por favor, verifique as temperaturas inseridas.";
    }

    if (isValidAirHumidiry()) {
      return "Por favor, insira uma umidade do ar válida em %.";
    }

    if (isValidAirVelocity()) {
      return "Por favor, insira uma velocidade do vento válida em km/h.";
    }

    if (isValidDataIniEnd()) {
      return "A data inicial não pode ser maior que a final.";
    }

    if (isValidIniEndHour()) {
      return "Os horários informados são iguais.";
    }

    return null;
  };

  const saveOnClick = async (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    const showSuccessAlert = (message) => {
      setOpenModal(true);
      setContentModal(
        <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alertavel title="" type="success" open={true} content={message} />
          </Grid>
        </Grid>
      );
    };

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    const itens = cards.map((card) => ({
      id: cardData.id[card.id] || "",
      item: cardData.item[card.id],
      grupo: cardData.grupo[card.id],
      unidade_item: cardData.unidade_item[card.id],
      dose_hectare: cardData.dose_hectare[card.id],
      dose_tanque: cardData.dose_tanque[card.id],
      ordem_adicao: cardData.ordem_adicao[card.id],
    }));

    formData.itens = itens;

    showLoading(true);
    var result = null
    if (dataId) {
      const lista_talhoes = formData.talhoes.map((val) => val.id);
      const dataToSubmit = { ...formData, talhoes: lista_talhoes };

      const prescriptions = new AgronomicPrescription(token);
      result = await prescriptions.UpdateAgronomicPrescription(dataId, dataToSubmit)
    } else {
      const lista_talhoes = formData.talhoes.map((val) => val.id);
      delete formData.id;

      const dataToSubmit = { ...formData, talhoes: lista_talhoes };

      const prescriptions = new AgronomicPrescription(token);
      result = await prescriptions.AddAgronomicPrescription(dataToSubmit);
    }
    showLoading(false);

    if (result.erro !== undefined) {
      let mensagem = ErrorToStringFix(result.erro)
      setContentAlert(mensagem);
      setOpenAlert(true);
    } else {
      showSuccessAlert("Dados Atualizados com sucesso");
    }

  }

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  useEffect(() => {
    LoadGroups();
    LoadFarm();
    LoadCulture();
    LoadQuantify();
    LoadMachinery();
    LoadContractors();
  }, [token, LoadFarm, LoadCulture, LoadMachinery, LoadQuantify, LoadGroups, LoadContractors]);

  useEffect(() => {
    const { tipo, data: dataEdit } = state || {};

    const loadData = async () => {

      setTitle("Editando Receita - #" + dataEdit.id);

      setBtnReset(
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container mb={2}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Button className="successBtn" variant="contained" fullWidth onClick={handleResetPage}>
                Nova Receita
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );

      if (dataEdit.itens.length === 0) {
        setCards([{ id: 0 }])
      } else {
        let talhoes = [];
        const initialCards = dataEdit.itens.map((_, index) => ({ id: index }));
        const areaTotal = dataEdit.talhoes.reduce((total, val) => total + parseFloat(val.tamanho??0), 0);

        setCards(initialCards);

        LoadFields(dataEdit.fazenda)

        dataEdit.talhoes.forEach((val, i) => {
          talhoes.push({ id: val.id, nome: val.nome, tamanho: val.tamanho });
        })

        for (const [index, item] of dataEdit.itens.entries()) {
          setCardData((prevData) => ({ ...prevData, id: { ...prevData['id'], [index]: item.id } }));
          setCardData((prevData) => ({ ...prevData, grupo: { ...prevData['grupo'], [index]: item.grupo } }));
          setCardData((prevData) => ({ ...prevData, dose_tanque: { ...prevData['dose_tanque'], [index]: item.dose_tanque } }));
          setCardData((prevData) => ({ ...prevData, dose_hectare: { ...prevData['dose_hectare'], [index]: item.dose_hectare } }));
          setCardData((prevData) => ({ ...prevData, ordem_adicao: { ...prevData['ordem_adicao'], [index]: item.ordem_adicao } }));
          setCardData((prevData) => ({ ...prevData, unidade_item: { ...prevData['unidade_item'], [index]: item.unidade_item } }));

          await LoadItem(item.grupo, index);
          setCardData((prevData) => ({ ...prevData, item: { ...prevData['item'], [index]: item.item } }));
        };

        setFormData({
          id: dataEdit.id,
          area: areaTotal,
          obs: dataEdit.obs,
          contratante: dataEdit.contratante,
          cultura: dataEdit.cultura,
          fazenda: dataEdit.fazenda,
          umidade_ar: dataEdit.umidade_ar,
          data_final: dataEdit.data_final,
          hora_final: dataEdit.hora_final,
          data_inicial: dataEdit.data_inicial,
          hora_inicial: dataEdit.hora_inicial,
          vazao_hectare: dataEdit.vazao_hectare,
          temperatura_max: dataEdit.temperatura_max,
          temperatura_min: dataEdit.temperatura_min,
          tipo_maquinario: parseInt(dataEdit.tipo_maquinario),
          velocidade_vento: dataEdit.velocidade_vento,
          capacidade_tanque: dataEdit.capacidade_tanque,
          talhoes: talhoes,
        });

      }
    }

    if (tipo === "edicao" && dataEdit) {
      loadData();
    } else {
      handleResetPage();
    }

  }, [state, LoadItem, LoadFields, handleResetPage]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Receituário Agronômico</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      {btnReset}

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

        <Grid container>
          <Typography variant="h6" className="titles">Data e Hora</Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              type="date"
              id="data_inicial"
              label="Início da Aplicação"
              value={formData.data_inicial}
              InputLabelProps={{ shrink: true, required: true }}
              onChange={(event) => setFormData({ ...formData, data_inicial: event.target.value })}
            />

          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              type="date"
              id="data_final"
              label="Fim da Aplicação"
              value={formData.data_final}
              InputLabelProps={{ shrink: true, required: true }}
              onChange={(event) => setFormData({ ...formData, data_final: event.target.value })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="horaini"
              label="Horário Inicial"
              InputLabelProps={{ shrink: true, required: true }}
              onChange={(event) => setFormData({ ...formData, hora_inicial: event.target.value })}
              type="time"
              value={formData.hora_inicial}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              type="time"
              id="horafim"
              label="Horário Final"
              value={formData.hora_final}
              InputLabelProps={{ shrink: true, required: true }}
              onChange={(event) => setFormData({ ...formData, hora_final: event.target.value })}
            />
          </Grid>
        </Grid>
      </Grid>

      {
        dataContratante.length === 0? <></> :
        <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
          <Grid container>
            <Typography variant="h6" className="titles">Contratante</Typography>
          </Grid>

          <Grid container>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AutocompleteSelect
                id="contratante"
                label="Contratante"
                isMultiple={false}
                required={false}
                disabled={false}
                data={dataContratante}
                value={formData.contratante}
                onChange={(event) => setFormData({ ...formData, contratante: event ? event.id : null })}
              />
            </Grid>
          </Grid>
        </Grid>
      }

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Cultura</Typography>
        </Grid>

        <Grid container>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AutocompleteSelect
              id="cultura"
              label="Cultura"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataCulture}
              value={formData.cultura}
              onChange={(event) => setFormData({ ...formData, cultura: event ? event.id : null })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Propriedade</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <AutocompleteSelect
              id="fazenda"
              label="Fazenda"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataProperty}
              value={formData.fazenda}
              onChange={handleFarmChange}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <AutocompleteSelect
              id="talhao"
              label="Talhão"
              isMultiple={true}
              required={true}
              disabled={false}
              data={dataField}
              value={formData.talhoes}
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="area"
              label="Área"
              disabled={true}
              value={formData.area}
              InputLabelProps={{ required: true }}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Equipamento</Typography>
        </Grid>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AutocompleteSelect
              id="maquinario"
              label="Tipo de Maquinário"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataMachinery}
              value={formData.tipo_maquinario}
              onChange={(event) => setFormData({ ...formData, tipo_maquinario: event ? event.id : null })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              type="number"
              id="capacidade_tanque"
              value={formData.capacidade_tanque || ""}
              label="Capacidade do Tanque"
              onChange={(event) => setFormData({ ...formData, capacidade_tanque: event.target.value })}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">Litros</InputAdornment>
              }}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              type="number"
              id="vazao_maquinario"
              value={formData.vazao_hectare || ""}
              label="Vazão do Maquinário"
              onChange={(event) => setFormData({ ...formData, vazao_hectare: event.target.value })}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">Litros</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {cards.map((card, index) => (
        <Grid item id={"card-" + card.id} key={card.id} lg={8} md={12} sm={12} xs={12} mb={2}>
          <Accordion id={"panel-header-" + card.id} aria-controls={"panel-content-" + card.id} className="card-form">
            <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
              <Grid container>
                <Grid item lg={10} md={10} sm={10} xs={10} display="flex">
                  <Typography className="titles" variant="h6">Produto {card.id + 1}</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} justifyContent="end" display="flex">
                  <Tooltip title="Deletar Produtos">
                    <Button className="ListBtn" data-id={cardData.id[card.id] || ""} onClick={(event) => { handleRemoveCard(event, card.id) }}>
                      <FontAwesomeIcon className="font-icons-list" icon={faTrash} />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>

                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <AutocompleteSelect
                    id={"grupo_" + card.id}
                    label="Grupo"
                    isMultiple={false}
                    required={true}
                    disabled={false}
                    data={dataGroup}
                    value={cardData.grupo[card.id]}
                    onChange={(event) => { handleGrupoChange(event, card.id) }}
                  />
                </Grid>

                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <AutocompleteSelect
                    id={"produto" + card.id}
                    label="Produto"
                    isMultiple={false}
                    required={true}
                    disabled={false}
                    data={dataItem[card.id] || []}
                    value={cardData.item[card.id]}
                    onChange={(event) => {
                      handleCardChange(event, card.id, 'item');
                    }}
                  />
                </Grid>

                <Grid className="grid-item" item lg={3.8} md={3.8} sm={12} xs={12}>
                  <TextField
                    type="number"
                    id={"ordenacao" + card.id}
                    label="Ordem de cauda"
                    value={cardData.ordem_adicao[card.id] || ""}
                    onChange={(event) => {
                      handleCardChange(event, card.id, 'ordem_adicao');
                    }}
                    InputLabelProps={{ required: true }}
                  />
                </Grid>

                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    type="number"
                    id={"volume_hectare" + card.id}
                    label="Volume por Hectare"
                    value={cardData.dose_hectare[card.id] || ""}
                    InputLabelProps={{ required: true }}
                    onChange={(event) => {
                      handleCardChange(event, card.id, 'dose_hectare');
                    }}
                  />
                </Grid>

                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <AutocompleteSelect
                    id={"unidade" + card.id}
                    label="Unidade de Medida"
                    isMultiple={false}
                    required={true}
                    disabled={false}
                    data={dataUnit}
                    value={cardData.unidade_item[card.id]}
                    onChange={(event) => { handleCardChange(event, card.id, 'unidade_item') }}
                  />
                </Grid>

                <Grid className="grid-item" item lg={3.8} md={3.8} sm={12} xs={12}>
                  <TextField
                    type="number"
                    id={"quantidade" + card.id}
                    disabled={true}
                    label="Quantidade por Tanque"
                    value={cardData.dose_tanque[card.id] || ""}
                    InputProps={{
                      required: true,
                      endAdornment: <InputAdornment position="start">{dataAdornment[card.id] || ""}</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}

      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} textAlign="center" justifyContent="center">
        <Tooltip title="Adicionar Produtos">
          <Button className="BackButton" variant="contained" onClick={handleAddCard}>
            <FontAwesomeIcon className="font-icons" icon={faPlus} />
          </Button>
        </Tooltip>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Condições Climáticas</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="number"
              id="temp_min"
              label="Temp. Mínima"
              value={formData.temperatura_min}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">ºC</InputAdornment>,
              }}
              onChange={(event) => setFormData({ ...formData, temperatura_min: event.target.value })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="number"
              id="temp_max"
              label="Temp. Máxima"
              value={formData.temperatura_max}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">ºC</InputAdornment>,
              }}
              onChange={(event) => setFormData({ ...formData, temperatura_max: event.target.value })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="number"
              id="velocidade_vento"
              label="Velocidade Máx. Vento"
              value={formData.velocidade_vento}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">km/h</InputAdornment>,
              }}
              onChange={(event) => setFormData({ ...formData, velocidade_vento: event.target.value })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="number"
              id="umidade"
              label="Umidade do Ar"
              value={formData.umidade_ar}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              onChange={(event) => setFormData({ ...formData, umidade_ar: event.target.value })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Observações</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              multiline
              rows={4}
              id="observacao"
              label="Observação"
              value={formData.obs}
              onChange={(event) => setFormData({ ...formData, obs: event.target.value })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1} textAlign="center">
        <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Receitas"
      />

      <ConfirmDialog
        text="Deletar"
        title={title}
        open={openDialog}
        content={contentDialog}
        onClose={handleRemoveCancel}
        onConfirm={handleRemoveConfirm}
      />

    </Grid >
  );
};


export default AddAgronomicPrescription;