import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import CardContent from '@mui/material/CardContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faFilter, faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import Tables from '../../../Components/Table';
import Alertavel from '../../../Components/Alertavel';
import ConfirmDialog from '../../../Components/Dialog/Confirm';
import ResponsiveDialog from '../../../Components/Dialog/Dialog';

import { User } from '../../../Models/User';
import { Movement } from '../../../Models/Inventory/Movement';

import { dataUnit } from '../constants';

import { getCurrentDate, getOriginDestiny } from '../../../Services/globalFunction';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const headers = ['Origem', 'Destino', 'Data'];

const ListMovements = () => {
  const [title, setTitle] = useState("Listagem de Movimentações");
  const [movements, setMovements] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const currentDate = getCurrentDate(0, 0, '');
  const inidate = getCurrentDate(0, 30, 'subtrai');

  const [fullscreen, setFullscreen] = useState();
  const [text, setText] = useState();

  const [contentModal, setContentModal] = useState('');
  const [contentAlert, setContentAlert] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [dataIni, setDataIni] = useState(inidate);
  const [dataEnd, setDataEnd] = useState(currentDate);
  const [dataMov, setDataMov] = useState([]);

  const user = User.searchUser();
  const token = user.token;

  // Busca todas as movimentações na API através do models
  const LoadMovements = useCallback(async () => {
    const parametros = {
      data_inicial: dataIni,
      data_final: dataEnd
    };

    const movements = new Movement(token);

    const dataMovement = await movements.SearchInventoryMovements(parametros).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as movimentações. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataMov(dataMovement);


  }, [token, dataIni, dataEnd]);

  // Visualizar informações completas das movimentações no modal
  const handleView = (id) => {

    setTitle("Listagem da Movimentação - #" + id);

    let dataView = dataMov.find((val) => val.id === id);
    let dataEditItens = dataView.itens.map((val) => val);

    const [day, month, year] = dataView.data.split("/");
    const convertedData = `${year}-${month}-${day}`;

    const { origin, destination } = getOriginDestiny(dataView.tipo_origem, dataView.tipo_destino, dataView)

    const mapUnit = (unidadeId) => {
      const unidade = dataUnit.find((unidade) => unidade.id === unidadeId);
      return unidade ? unidade.nome : '';
    };

    const content = () => {
      return (
        <Grid container justifyContent="center">

          <Helmet>
            <title>{title} - Estoque</title>
          </Helmet>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
            <Typography className="titles-pages" variant="h5">{title}</Typography>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6"  className="titles">Data</Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled type="date" label="Data" value={convertedData} />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled type="time" label="Hora" value={dataView.horario} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6"  className="titles">Movimentação</Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled label="Origem" value={origin} />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled label="Destino" value={destination} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Grid container>
              <Typography variant="h6" className="titles">Observação</Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField className="disabled-textField" disabled multiline rows={4} id="observacao" label="Observação" value={dataView.observacao} />
              </Grid>
            </Grid>
          </Grid>

          {
            dataEditItens.map((val, index) => (
              <Grid key={val.id} item lg={8} md={12} sm={12} xs={12} mb={4}>
                <Card className="card-form">
                  <CardContent>
                    <Grid container spacing={2} marginBottom={2} justifyContent="center">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography  className="titles">Produto {index + 1}</Typography>
                      </Grid>

                      <Grid item lg={3} md={6} sm={12} xs={12}>
                        <TextField className="disabled-textField" disabled label="Grupo" value={val.grupo_nome} />
                      </Grid>

                      <Grid item lg={3} md={6} sm={12} xs={12}>
                        <TextField className="disabled-textField" disabled label="Produto" value={val.item_nome} />
                      </Grid>

                      <Grid item lg={2} md={6} sm={12} xs={12}>
                        <TextField className="disabled-textField" disabled label="Unidade" value={parseInt(val.unidade_item_medida)} />
                      </Grid>

                      <Grid item lg={2} md={6} sm={12} xs={12}>
                        <TextField className="disabled-textField" disabled label="Unidade de Medida" value={mapUnit(val.unidade_item)} />
                      </Grid>

                      <Grid item lg={2} md={6} sm={12} xs={12}>
                        <TextField className="disabled-textField" disabled label="Quantidade" value={parseInt(val.quantidade)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))
          }
        </Grid >
      );
    }

    setContentModal(content);
    setFullscreen(true);
    setOpenModal(true);
  };

  // Click no botão deletar
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setText("Deletar");
    setTitle("Deletar Registro");
    setContentModal("Você tem certeza que deseja excluir esta Movimentação?");
    setOpenDialog(true);
  };

  //Change Aprovar
  const handleCheck = useCallback(async (id) => {
    const approve = new Movement(token);
    approve.ApproveMovement(id);

    const msgSuccess = (
      <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Alertavel title="" type="success" open={true} content="Movimentação aprovada com sucesso" />
        </Grid>
      </Grid>
    );

    setMovements([]);
    setDataMov([]);

    setTitle("Aprovação");
    setContentModal(msgSuccess);
    setFullscreen(false);
    setOpenModal(true);
  }, [token]);

  // Click confirmar remoção
  const handleDeleteConfirmed = async () => {

    const usuario = User.searchUser();
    const token = usuario.token;

    const remove = new Movement(token);
    remove.DeleteInventoryMovement(deleteItemId);

    setMovements([]);
    setDataMov([]);

    setOpenDialog(false);
  };

  // Click cancelar remoção
  const handleDeleteCanceled = () => {
    setOpenDialog(false);
  };

  //Change Data Inicial
  const handleDataIni = (event) => {
    const selectedValue = event.target.value
    setDataIni(selectedValue);
  };

  //Change Data Final
  const handleDataEnd = (event) => {
    const selectedValue = event.target.value
    setDataEnd(selectedValue);
  };

  // Botões da tabela
  const actions = (id, status) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => handleView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        {status !== 'S' && (
          <>
            <Tooltip title="Deletar">
              <Button className="ListBtn" onClick={() => handleDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
            </Tooltip>
            <Tooltip title="Aprovar">
              <Button className="ListBtn" onClick={() => handleCheck(id)}><FontAwesomeIcon className="font-icons-list" icon={faCheck} /></Button>
            </Tooltip>
          </>
        )}
      </ButtonGroup>
    );
  };

  // Botão de filtros
  const filterClick = async () => {
    setOpenAlert(false);
    if (dataIni && dataEnd) {
      await LoadMovements();
    } else {
      setContentAlert("É necessário informar a Data Inicial e a Data Final para fazer a filtragem");
      setOpenAlert(true);
    }
  };

  const modalClose = () => {
    setTitle("Listagem de Movimentações");
    setOpenModal(false);
    setFullscreen(false);
  };

  useEffect(() => {
    const ValidadeMovements = () => {

      const movements = dataMov.map((val) => {
        const { origin, destination } = getOriginDestiny(val.tipo_origem, val.tipo_destino, val)

        const MovDatatable = {
          id: val.id,
          tipo_origem: origin,
          tipo_destino: destination,
          data: val.data,
          status: val.aprovado
        };

        return MovDatatable;
      });

      setMovements(movements);
    };

    ValidadeMovements();
  }, [dataMov]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Estoque</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Accordion defaultExpanded id="panel-header" aria-controls="panel-content">
          <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
            <Grid container>
              <Typography className="titles" variant="h6">Selecione os dados abaixo:</Typography>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Grid item lg={5} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  value={dataIni}
                  label="Data Inicial"
                  error={!dataIni}
                  onChange={(event) => handleDataIni(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={5} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  value={dataEnd}
                  label="Data Final"
                  error={!dataEnd}
                  onChange={(event) => handleDataEnd(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}><FontAwesomeIcon className="font-icons" icon={faFilter} /> Filtrar</Button>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" >
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <ConfirmDialog
                text={text}
                open={openDialog}
                onClose={handleDeleteCanceled}
                onConfirm={handleDeleteConfirmed}
                title={title}
                content={contentModal}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} id="dataTable">
        <Card className="card-form">
          <CardContent>
            <Tables headers={headers} rows={movements} actions={actions} />
          </CardContent>
        </Card>
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={fullscreen}
        openModal={openModal}
        onClose={modalClose}
        title={title}
      />

    </Grid>
  );
}

export default ListMovements;
