import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Card, CardActionArea, CardContent } from '@mui/material';

import { User } from '../../../Models/User';
import { Inventory } from '../../../Models/Inventory/Inventory';
import { Company } from '../../../Models/Organization/Company';
import { Property } from '../../../Models/Organization/Property';

import Tables from '../../../Components/Table';
import Alertavel from '../../../Components/Alertavel';
import ResponsiveDialog from '../../../Components/Dialog/Dialog';

import { dataUnit } from '../constants';

import "../../../App.css";
import "../css/index.css";

export default function ListItens() {
  const [fullscreen, setFullscreen] = useState();
  const [title, setTitle] = useState("Listagem de Itens");

  const [properties, setProperties] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [contentModal, setContentModal] = useState('');
  const [contentAlert, setContentAlert] = useState("");

  const user = User.searchUser();
  const companyId = user.empresa_id;
  const token = user.token;

  const headers = ['Nome', 'Grupo', 'Unidade', 'Quantidade']

  const mapUnit = (unidadeId) => {
    const unidade = dataUnit.find((unidade) => unidade.id === unidadeId);
    return unidade ? unidade.nome : '';
  };

  const LoadLocal = useCallback(async () => {
    const properties = new Property(token);
    const subsidiaries = new Company(token);

    const Properties = await properties.SearchProperty().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para consultar o estoque das propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    const Subsidiaries = await subsidiaries.SearchSubsidiaries(companyId).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para consultar o estoque das filiais. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setProperties(Properties);
    setSubsidiaries(Subsidiaries);

  }, [token, companyId]);

  const renderModal = (dados, tipo) => {
    let title = 'Dados de Estoque';

    const Inventory = dados.map((val, index) => {
      if (val.fazenda_nome !== undefined && tipo === 'propriedade') {
        title = 'Dados de estoque - ' + val.fazenda_nome;
      } else if (val.filial_nome !== undefined && tipo === 'filial') {
        title = 'Dados de estoque - ' + val.filial_nome;
      }

      return {
        id: val.item_id + '-' + index,
        nome: val.item_nome,
        grupo: val.grupo_nome,
        unidade: `${parseFloat(val.unidade_item_medida)} ${mapUnit(val.unidade_item)}`,
        quantidade: parseFloat(val.quantidade) > 0 ? parseFloat(val.quantidade) : "Sem Estoque",
      };
    });

    const tabela = () => (
      <Grid container justifyContent="center">

        <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
          <Typography className="titles-pages" variant="h5">{title}</Typography>
        </Grid>

        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Card className="card-form">
            <CardContent>
              <Tables headers={headers} rows={Inventory} actions={null} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );

    setContentModal(tabela);
    setFullscreen(true);
    setOpenModal(true);
  };

  const handleLocal = async (event) => {
    const button = event.currentTarget;
    const tipo = button.getAttribute('data-tipo');
    const value = button.getAttribute('value');
    let parameters = {};

    if (tipo === 'propriedade') {
      parameters = { fazendas: value }
    } else {
      parameters = { filiais: value }
    }

    const inventory = new Inventory(token);
    await inventory.CheckInventory(parameters).then(response => {
      if (!response.erro) {
        renderModal(response.lista, tipo);
      } else {
        setContentModal("Não existem dados de estoque para essa localidade!");
        setTitle('Aviso');
        setOpenModal(true);
      }
    });
  }

  const modalClose = () => {
    setFullscreen(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (companyId !== 'null') {
      LoadLocal();
    } else {
      setContentAlert("Você não possui uma empresa vinculada ao seu usuário. Por favor, entre em contato com um administrador para obter assistência.");
      setOpenAlert(true);
    }
  }, [LoadLocal, companyId]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Estoque</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item lg={10} md={12} sm={12} xs={12}>
          <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        {properties.length > 0 || subsidiaries.length > 0 ?
          <>
            <Grid item lg={12} md={12} sm={12} xs={12} mb={3} margin={1}>
              <Alertavel title="" type="info" open={true} content={'Clique em uma propriedade ou filial para consultar o estoque.'} />
            </Grid>

            {subsidiaries.length > 0 ?
              <Grid item className="divLocal" lg={12} md={12} sm={12} xs={12}>

                <Grid item lg={12} md={4} sm={4} xs={3} mb={3} margin={1}>
                  <Typography variant="h6" className="title-accordion">Filiais</Typography>
                </Grid>

                {subsidiaries.map((filial) => (
                  <Grid item lg={2} md={5.5} sm={5.5} xs={5.5} mb={3} key={filial.id} margin={1}>
                    <CardActionArea component="a" onClick={handleLocal} className="btn-local" value={filial.id} data-tipo='filial'>
                      <Card className="cards-local">
                        <CardContent>
                          {filial.nome}
                        </CardContent>
                      </Card>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
              : ''}

            {properties.length > 0 ?
              <Grid item className="divLocal" lg={12} md={12} sm={12} xs={12}>

                <Grid item lg={12} md={4} sm={4} xs={3} mb={3} margin={1}>
                  <Typography variant="h6" className="title-accordion">Propriedades</Typography>
                </Grid>

                {properties.map((propriedade) => (
                  <Grid item lg={2} md={5.5} sm={5.5} xs={5.5} mb={3} key={propriedade.id} margin={1}>
                    <CardActionArea component="a" onClick={handleLocal} className='btn-local' value={propriedade.id} data-tipo='propriedade'>
                      <Card className="cards-local">
                        <CardContent>
                          {propriedade.nome}
                        </CardContent>
                      </Card>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid> : ''}
          </> : (
            <Grid item className="divLocal" lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={12} md={4} sm={4} xs={3} mb={3} margin={1}>
                <Alertavel title="Atenção" type="warning" open={true} content={'Nenhuma propriedade ou filial encontrada. Por favor, entre em contato com um administrador para obter assistência.'} />
              </Grid>
            </Grid>
          )}
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={fullscreen}
        openModal={openModal}
        onClose={modalClose}
        title={title}
      />
    </Grid >
  );
}