import { DefaultModel } from "../DefaultModel";
import { searchContractor} from "../../Services/API/api";

export class Contractor extends DefaultModel {

  async SearchContractor(type) {
    const response = await searchContractor(this.token, type);
    return (response) || []; 
  }

}

