import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from "react";
import { useNavigate} from "react-router-dom";

import { Grid, Button, TextField, Accordion, Typography, AccordionSummary, AccordionDetails, Tooltip} from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import Alertavel from "../../../Components/Alertavel";
import ConfirmDialog from "../../../Components/Dialog/Confirm";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import ProductAutocomplete from "../../../Components/Selects/ProductAutocomplete";
import AutocompleteSelect from '../../../Components/Selects/AutocompleteSelect';

import { getCurrentDate } from "../../../Services/globalFunction";

import { User } from "../../../Models/User";
import { Movement } from "../../../Models/Inventory/Movement";
import { Company } from "../../../Models/Organization/Company";
import { Inventory } from "../../../Models/Inventory/Inventory";
import { ItemGroup } from "../../../Models/Inventory/ItemGroup";
import { Property } from "../../../Models/Organization/Property";
import { Provider } from "../../../Models/Organization/Provider";

import { dataMovements, dataUnit } from '../constants';

import "../../../App.css";

const AddMovements = ({ module }) => {
  const [title, setTitle] = useState("Cadastro de Movimentações");

  const [isResetProduct, setIsResetProduct] = useState(false);
  const [cards, setCards] = useState([{ id: 0 }]);
  const currentDate = getCurrentDate(0, 0, '');

  const navigate = useNavigate();
  const user = User.searchUser();
  const companyId = user.empresa_id;
  const token = user.token;

  const [formData, setFormData] = useState({ id: "", data: currentDate, horario: "", origem_id: "", destino_id: "", observacao: "", movimentacao: "" });
  const [cardData, setCardData] = useState({ id: "", item: "", grupo: "", quantidade: "", unidade_item: "", unidade_item_medida: "" });

  const [openDialog, setOpenDialog] = useState(false); // Status do Dialog
  const [openModal, setOpenModal] = useState(false); // Status do Modal
  const [openAlert, setOpenAlert] = useState(false); // Status do Alert

  const [contentAlert, setContentAlert] = useState(""); // Conteudo dos Alertaveis
  const [contentModal, setContentModal] = useState(""); // Conteudo dos Alertaveis

  const [dataItem, setDataItem] = useState([]);	// Dados de Produto
  const [dataDestiny, setDataDestiny] = useState([]);	// Dados de Destino
  const [dataOrigin, setDataOrigin] = useState([]);	// Dados de Origem
  const [dataGroup, setDataGroup] = useState([]);	// Dados de Grupo

  const [deleteItemId, setDeleteItemId] = useState({});	// Armazena a ID do item clicado que vai ser deletado
  const [totalAmount, setTotalAmount] = useState("");

  const requests = useCallback(async () => {
    try {
      const searchField = [{ id: 1, nome: "Campo" }];

      const property = new Property(token);
      const subsidiaries = new Company(token);
      const provider = new Provider(token);

      const fetchData = async (dataOrigemFn, dataDestinoFn) => ({
        dataOrigin: await dataOrigemFn,
        dataDestiny: await dataDestinoFn,
      });

      const movementMap = {
        1: () => fetchData(provider.SearchProvider(), subsidiaries.SearchSubsidiaries(companyId)),
        2: () => fetchData(subsidiaries.SearchSubsidiaries(companyId), provider.SearchProvider()),
        3: () => fetchData(subsidiaries.SearchSubsidiaries(companyId), property.SearchProperty()),
        4: () => fetchData(property.SearchProperty(), subsidiaries.SearchSubsidiaries(companyId)),
        5: () => fetchData(property.SearchProperty(), property.SearchProperty()),
        6: () => fetchData(property.SearchProperty(), Promise.resolve(searchField)),
        7: () => fetchData(Promise.resolve(searchField), property.SearchProperty()),
        default: () => ({ dataOrigin: [], dataDestiny: [] }),
      };

      return await (movementMap[formData.movimentacao] || movementMap.default)();
    } catch (error) {
      console.error("Erro ao buscar dados de movimentação:", error);
      return { dataOrigin: [], dataDestiny: [] };
    }
  }, [token, companyId, formData.movimentacao]);

  const LoadGroups = useCallback(async () => {
    let Groups = new ItemGroup(token);
    let Group = await Groups.SearchGroup().then(response => {
      if (!response.erro) {
        return response
      } else {
        setContentAlert("Você não possui permissão para listar os grupos. Por favor, entre em contato com um administrador para obter assistência");
        setOpenAlert(true);
        return [];
      }
    });
    setDataGroup(Group);
  },[token]);

  const LoadItem = useCallback(async (groupId, index) => {
    const movement = formData.movimentacao;
    const originId = formData.origem_id;
    const key = movement === 2 || movement === 3 ? 'filiais' : 'fazendas';

    let dataToUpdate = {};
    if (movement === 1 || movement === 7) {
      const item = new ItemGroup(token);
      const product = await item.SearchItemGroup(groupId);
      dataToUpdate = { [index]: product };

    } else if (originId) {
      const filters = { [key]: originId, grupos: groupId };
      const product = new Inventory(token);
      const results = await product.CheckInventory(filters).then(response => {
        if (!response.erro) {
          return response.lista
        } else {
          setContentAlert("Você não possui permissão para listar os itens. Por favor, entre em contato com um administrador para obter assistência");
          setOpenAlert(true);
          return [];
        }
      });

      const products = results.map((val) => ({
        id: val.item_id,
        nome: val.item_nome + " - [" + parseInt(val.unidade_item_medida) + " " + val.unidade_item + "]",
        quantidade: val.quantidade,
        unidade_medida: val.unidade_item,
        unidade: val.unidade_item_medida,
        item_variacao: val.item_variacao
      }));

      dataToUpdate = { [index]: products };
    }

    setDataItem((prevData) => ({ ...prevData, ...dataToUpdate }));
  }, [token, formData.movimentacao, formData.origem_id]);

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'estoque');
    navigate(`/estoque${routes.rota}`);

    setTitle("CADASTRO DE MOVIMENTAÇÕES");
    setCards([{ id: 0 }]);

    setDataItem([]);
    setDataDestiny([]);
    setDataOrigin([]);
    setDataGroup([]);

    setFormData({ id: "", data: "", horario: "", origem_id: "", destino_id: "", tipo_origem: "", tipo_destino: "", observacao: "" });
    setCardData({ id: "", item: "", grupo: "", quantidade: "", unidade_item: "", unidade_item_medida: "" });
    setIsResetProduct(true);

    setContentModal("");
    setContentAlert("");
    setOpenAlert(false);
  }, [module.rotas, navigate]);

  // Campos

  const handleMovChange = (event) => {
    if (event) {

      const updatedDataProduct = [];

      cards.forEach((card) => {
        setCardData((prevData) => ({ ...prevData, grupo: { ...prevData['grupo'], [card.id]: "", } }));
        updatedDataProduct.push([]);
      });

      if (updatedDataProduct[0] && updatedDataProduct[0].length > 0) {
        setDataItem(updatedDataProduct);
      }

      setCardData({ ...cardData, unidade_item: "", unidade_item_medida: "" });
      setFormData({ ...formData, origem_id: "", destino_id: "", movimentacao: event.id });
    }
  };

  const handleOriginChange = (event) => {
    if (event) {

      const updatedDataProduct = [];

      cards.forEach((card) => {
        setCardData((prevData) => ({ ...prevData, grupo: { ...prevData['grupo'], [card.id]: "", } }));
        updatedDataProduct.push([]);
      });

      setDataItem(updatedDataProduct);
      setFormData({ ...formData, origem_id: event.id });
    }
  }

  const handleGroupChange = async (event, index) => {
    if (event) {
      setDataItem((prevData) => ({ ...prevData, [index]: [] }));
      setCardData((prevData) => ({ ...prevData, grupo: { ...prevData['grupo'], [index]: event.id } }));

      await LoadItem(event.id, index);
      setCardData((prevData) => ({ ...prevData, item: { ...prevData['item'], [index]: [] } }));
    }
  };

  const handleCardChange = (event, index, field) => {
    if (event && field === "item") {
      const unidadeMedida = event.unidade_medida || "";
      const quantidade = event.quantidade || 0;
      const unidade = event.unidade || "";
      const nome = event.nome || "";

      setTotalAmount((prevData) => ({
        ...prevData,
        item: { ...prevData.item, [index]: nome },
        quantidade: { ...prevData.quantidade, [index]: quantidade },
        unidade_item: { ...prevData.unidade_item, [index]: unidadeMedida },
        unidade_item_medida: { ...prevData.unidade_item_medida, [index]: unidade },
      }));
  
      setCardData((prevData) => ({ ...prevData, [field]: { ...prevData[field], [index]: { id: event.id, variacao: event.item_variacao } }}));
    } else if (event) {
      setCardData((prevData) => ({...prevData, [field]: { ...prevData[field], [index]: event.id } }));
    }

    if ((field === 'unidade_item_medida' || field === 'quantidade') && event) {
      const { value } = event.target;
      setCardData((prevData) => ({ ...prevData, [field]: { ...prevData[field], [index]: value } }));
    }
  };

  // Cards

  const handleAddCard = () => {
    const nextId = cards.length > 0 ? cards[cards.length - 1].id + 1 : 0;
    setCards([...cards, { id: nextId }]);
  };

  const handleRemoveCard = (event, index) => {
    setDeleteItemId({});

    const dataId = event.currentTarget.getAttribute("data-id");

    if (dataId) {
      setDeleteItemId({ id: dataId, index: index });
      setTitle("Deletar Item");
      setContentModal("Você tem certeza que deseja excluir este Item?");
      setOpenDialog(true);
    } else {
      delete cardData.grupo[index];
      delete cardData.item[index];
      delete cardData.unidade_item[index];
      delete cardData.unidade_item_medida[index];
      delete cardData.quantidade[index];

      const updatedCards = cards.filter((card) => card.id !== index);
      setCards(updatedCards);
    }
  };

  const handleRemoveConfirm = async () => {
    const user = User.searchUser();
    const token = user.token;

    const id = deleteItemId.id;
    const index = deleteItemId.index;

    const remove = new Movement(token);
    remove.DeleteInventoryItem(id)

    delete cardData.id[index];
    delete cardData.grupo[index];
    delete cardData.item[index];
    delete cardData.unidade_item[index];
    delete cardData.unidade_item_medida[index];
    delete cardData.quantidade[index];

    const updatedCards = cards.filter((card) => card.id !== index);
    setCards(updatedCards);

    setOpenDialog(false);
  };

  const handleRemoveCancel = () => {
    setOpenDialog(false);
  };

  // Validação - Gravação - Modal

  const validateForm = () => {
    const requiredFields = [
      'movimentacao',
      'data',
      'horario',
      'origem_id',
      'destino_id'
    ];

    const { movimentacao, origem_id, destino_id } = formData;

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field]);

    const isAnyCardInvalid = cards.some((card) =>
      !cardData.grupo[card.id] ||
      !cardData.item[card.id] ||
      !cardData.quantidade[card.id] ||
      !cardData.unidade_item[card.id] ||
      !cardData.unidade_item_medida[card.id]
    );

    if (isAnyFieldInvalid || isAnyCardInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    if (movimentacao === 5 && origem_id === destino_id) {
      return "Não é possível fazer uma transferência para o mesmo local. Você precisa mudar os locais de Origem ou Destino.";
    }

    if (movimentacao !== 1 && movimentacao !== 7) {

      const errorCard = cards.find((card) => {
        const cardQuantidade = cardData.quantidade?.[card.id];
        const totalQuantidade = totalAmount?.quantidade[card.id];
        return cardQuantidade > parseInt(totalQuantidade);
      });

      if (errorCard) {
        return `A quantidade inserida para o produto ${totalAmount.item[errorCard.id]} excede a quantidade total em estoque ( ${parseInt(totalAmount.quantidade[errorCard.id])} itens ). Por favor, insira uma quantidade válida.`;
      }
    }

    const errorQTDCard = cards.find((card) => {
      return cardData.quantidade[card.id] <= 0;
    });

    if (errorQTDCard) {
      return "Você está tentando movimentar um número negativo de produtos.";
    }

    return null;
  };

  const saveOnClick = async (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    const showSuccessAlert = (message) => {
      setOpenModal(true);
      setContentModal(
        <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alertavel title="" type="success" open={true} content={message} />
          </Grid>
        </Grid>
      );
    };

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    const movimentacao = dataMovements.find((mov) => mov.id === formData.movimentacao);

    const itens = cards.map((card) => ({
      id: cardData.id[card.id] || "",
      item: cardData.item[card.id].id,
      grupo: cardData.grupo[card.id],
      quantidade: parseFloat(cardData.quantidade[card.id]),
      unidade_item: cardData.unidade_item[card.id],
      unidade_item_medida: parseFloat(cardData.unidade_item_medida[card.id]),
    }));

    formData.itens = itens;
    formData.tipo_origem = movimentacao.tipo_origem;
    formData.tipo_destino = movimentacao.tipo_destino;

    if (!dataId) {

      delete formData.id;
      const save = new Movement(token);
      const result = await save.AddInventoryMovement(formData);

      if (result.erro !== undefined) {
        const [campo, mensagem] = result.erro[0];

        if (campo === "data") {
          setContentAlert("A data informada é inválida");
        } else {
          setContentAlert(mensagem);
        }

        setOpenAlert(true);
      } else {
        showSuccessAlert("Dados atualizados com sucesso");
      }

      showSuccessAlert("Dados Gravados com sucesso");
    }
  };

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (companyId && companyId !== 'null') {
        const { dataOrigin, dataDestiny } = await requests();
        setDataOrigin(dataOrigin);
        setDataDestiny(dataDestiny);
        await LoadGroups();
      } else {
        setContentAlert("Você não possui uma empresa vinculada ao seu usuário. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      }
    };

    fetchData();
  }, [token, companyId, formData.movimentacao, requests, LoadGroups]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Estoque</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />
        
        <Grid container>
          <Typography variant="h6" className="titles">Data</Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              type="date"
              id="data_evento"
              value={formData.data}
              label="Data do Evento"
              InputLabelProps={{ shrink: true, required: true }}
              onChange={(event) => setFormData({ ...formData, data: event.target.value })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              label="Hora do evento"
              type="time"
              value={formData.horario}
              onChange={(event) => setFormData({ ...formData, horario: event.target.value })}
              InputLabelProps={{ shrink: true, required: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Movimentação</Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AutocompleteSelect
              id="movimentacao"
              label="Tipo Movimentação"
              isMultiple={false}
              required={true}
              data={dataMovements}
              disabled={false}
              value={formData.movimentacao}
              onChange={handleMovChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <AutocompleteSelect
              id="origem"
              label="Origem"
              isMultiple={false}
              required={true}
              data={dataOrigin}
              disabled={false}
              value={formData.origem_id}
              onChange={handleOriginChange}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <AutocompleteSelect
              id="destino"
              label="Destino"
              isMultiple={false}
              required={true}
              data={dataDestiny}
              disabled={false}
              value={formData.destino_id}
              onChange={(event) => setFormData({ ...formData, destino_id: event ? event.id : null })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Grid container>
          <Typography variant="h6" className="titles">Observação</Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            multiline
            rows={4}
            id="observacao"
            label="Observação"
            value={formData.observacao || ""}
            onChange={(event) => setFormData({ ...formData, observacao: event.target.value })}
          />
        </Grid>
      </Grid>

      {
        cards.map((card) => (
          <Grid id={"card-" + card.id} key={card.id} item mb={2}lg={8} md={12} sm={12} xs={12}>
            <Accordion id={"panel-header-" + card.id} aria-controls={"panel-content-" + card.id} className="card-form">
              <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
                  <Grid item lg={10} md={10} sm={10} xs={10} display="flex">
                    <Typography className="titles" variant="h6">Produto {card.id + 1}</Typography>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={2} justifyContent="end" display="flex">
                    <Tooltip title="Deletar Produtos">
                      <Button className="ListBtn" data-id={cardData.id[card.id] || ""} onClick={(event) => { handleRemoveCard(event, card.id) }}>
                        <FontAwesomeIcon className="font-icons-list" icon={faTrash} />
                      </Button>
                    </Tooltip>
                  </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={1}>

                  <Grid className="grid-item" item lg={2} md={2} sm={12} xs={12}>
                    <AutocompleteSelect
                      id={"grupo_" + card.id}
                      label="Grupo"
                      isMultiple={false}
                      required={true}
                      data={dataGroup}
                      disabled={false}
                      value={cardData.grupo[card.id]}
                      onChange={(event) => {
                        handleGroupChange(event, card.id);
                      }}
                    />
                  </Grid>

                  <Grid className="grid-item" item lg={4} md={2} sm={12} xs={12}>
                    <ProductAutocomplete
                      id={"produto" + card.id}
                      label="Produto"
                      data={dataItem[card.id] || ""}
                      value={cardData.item[card.id] || ""}
                      onChange={(event) => {
                        handleCardChange(event, card.id, 'item');
                      }}
                      isReset={isResetProduct}
                    />
                  </Grid>

                  <Grid className="grid-item" item lg={2} md={2} sm={12} xs={12}>
                    <TextField
                      type="number"
                      id="total_unidade"
                      label="Unidade"
                      InputLabelProps={{ required: true }}
                      value={cardData.unidade_item_medida[card.id] || ""}
                      disabled={false}
                      onChange={(event) => {
                        handleCardChange(event, card.id, 'unidade_item_medida');
                      }}
                    />
                  </Grid>

                  <Grid className="grid-item" item lg={2} md={2} sm={12} xs={12}>
                    <AutocompleteSelect
                      id="unidade"
                      label="Unidade de Medida"
                      isMultiple={false}
                      required={true}
                      data={dataUnit}
                      disabled={false}
                      value={cardData.unidade_item[card.id]}
                      onChange={(event) => {
                        handleCardChange(event, card.id, 'unidade_item');
                      }}
                    />
                  </Grid>

                  <Grid className="grid-item" item lg={2} md={2} sm={12} xs={12}>
                    <TextField
                      type="number"
                      id="quantidade"
                      label="Quantidade"
                      InputLabelProps={{ required: true }}
                      value={parseInt(cardData.quantidade[card.id]) || ""}
                      onChange={(event) => {
                        handleCardChange(event, card.id, 'quantidade');
                      }}
                    />
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      }

      <Grid container mb={8} justifyContent="center">
        <Tooltip title="Adicionar Produtos">
          <Button className="BackButton" variant="contained" onClick={handleAddCard}>
            <FontAwesomeIcon className="font-icons-list" icon={faPlus} />
          </Button>
        </Tooltip>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1} textAlign="center">
        <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Movimentações"
      />

      <ConfirmDialog
        text="Deletar"
        title={title}
        open={openDialog}
        content={contentModal}
        onClose={handleRemoveCancel}
        onConfirm={handleRemoveConfirm}
      />

    </Grid >
  );
};

export default AddMovements;
