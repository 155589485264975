import { useCallback, useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";

import { User } from "../../Models/User";
import { Essential } from "../../Models/Essential/Essential";

import Sidebar from "../../Components/Sidebar";

import AddAgronomicPrescription from "./Pages/AddAgronomicPrescription";
import ListAgronomicPrescription from "./Pages/ListAgronomicPrescription";

import "../../App.css";

import { AGRONOMIC_PRESCRIPTION_PERMISSION_NAME, REDIRECT_ROUTE_HOME, REDIRECT_ROUTE_LOGIN } from "../../Services/globalFunction";

const COMPONENT_MAPPING = { ListAgronomicPrescription, AddAgronomicPrescription };

export default function AgronomicRecipes() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [Allroutes, setAllRoutes] = useState([]);
  const [module, setModule] = useState([]);

  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user?.token;

  const loadModules = useCallback(async () => {
    const modules = new Essential(token);
    await modules.SearchModules().then((val) => {
      const modulesWithPermission = val.find(module => module.nome_permissao === AGRONOMIC_PRESCRIPTION_PERMISSION_NAME);
      const routesFiltered = modulesWithPermission.rotas.filter(rotas => rotas.tipo_rota !== 'EDICAO');

      if (routesFiltered) {
        setRoutes(routesFiltered);
        setAllRoutes(modulesWithPermission.rotas);
        setModule({ modulo: modulesWithPermission.id, permissao: modulesWithPermission.nome_permissao, rotas: modulesWithPermission.rotas });
      }
    });

  }, [token]);

  const handleRouteNavigation = (id, rota) => {
    const route = routes.find(route => route.id === id && route.rota === rota);
    if (route) {
      navigate(`/receituario-agronomico${route.rota}`);
    }
  };

  const validateUser = useCallback(() => {
    if (user?.isLoggedIn()) {
      const isModuleAvailable = user.modulos.some(element => element.nome_permissao === AGRONOMIC_PRESCRIPTION_PERMISSION_NAME);
      if (isModuleAvailable) {
        setIsLoggedIn(true);
      } else {
        navigate(REDIRECT_ROUTE_HOME);
      }
    } else {
      navigate(REDIRECT_ROUTE_LOGIN);
    }
  }, [navigate, user]);

  useEffect(() => {
    validateUser();
  }, [validateUser])

  useEffect(() => {
    if (isLoggedIn) {
      loadModules();
    }
  }, [loadModules, isLoggedIn]);

  return (
    <Sidebar isLoggedIn={isLoggedIn} routes={routes} onButtonClick={handleRouteNavigation}>
      <Routes>
        {Allroutes.map(({ id, rota, nome_funcao }) => {
          const Component = COMPONENT_MAPPING[nome_funcao];
          return (<Route key={id} path={rota} element={<Component module={module} />} />);
        })}
      </Routes>
    </Sidebar>
  );
}
